<template>
  <div class="w-full">
    <vx-card class="p-0 m-0 w-full">
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card
            no-shadow
            style="background-color: #ffca00; padding: 0% !important"
          >
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  style="font-size: 12px; color: black"
                  class="font-weight-bold"
                  >CERTIFICADO</span
                >
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card no-shadow no-border class="m-0 p-0">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  Certificamos que o estabelecimento abaixo identificado possui
                  um
                  <span class="font-bold" style="text-transform: uppercase">
                    PLANO PREVENTIVO DE
                    {{ dataCertificado.controle }}</span
                  >
                  e que o mesmo atende todas as especificações técnicas
                  estabelecidas na
                  <b>RESOLUÇÃO - RDC Nº 622, DE 09 DE MARÇO DE 2022 - ANVISA</b
                  >. A garantia especificada neste certificado está
                  condicionada às inspeções de avaliações e/ou
                  <b>MANUTENÇÕES PREVENTIVAS</b> comprovadas através das ordens
                  de serviço respectivas.
                </span>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card no-shadow class="border mx-0 my-1 p-0">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2"
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  <b>CONTRATO:</b> {{ dataCertificado.id_contrato }}
                </span>
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-input
                  class="inputx w-full"
                  v-validate="'required'"
                  name="validade"
                  type="date"
                  size="small"
                  color="dark"
                  @change="updateValidade()"
                  label-placeholder="VALIDADE"
                  v-model="dataCertificado.data_vencimento"
                />
              </vs-col>
              <vs-col
                vs-w="4"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2"
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  <b>EMISSÃO:</b> {{ dataCertificado.data_emissao }}
                </span>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card no-shadow class="border mx-0 my-1 p-0">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span
                  class="mx-2"
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  <b>EMPRESA:</b>
                  {{ dataCertificado.nome_cliente }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span
                  class="mx-2"
                  style="font-size: 12px; color: black; text-align: justify"
                >
                  <b>CNPJ:</b>
                  {{ dataCertificado.cpf_cnpj }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span
                  class="mx-2"
                  style="font-size: 11px; color: black; text-align: justify"
                >
                  <b>ENDEREÇO:</b>
                  {{ dataCertificado.endereco }}
                </span>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card no-shadow class="border mx-0 my-1 p-0">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <div class="w-full m-0 p-0">
                  <vs-col vs-w="12" class="my-0 mx-2">
                    <label style="font-size: 12px; color: black">
                      Endereço:
                      <b>{{ dataCertificado.nomeEndereco }}</b>
                    </label>
                  </vs-col>
                  <vs-row
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-type="flex"
                  >
                    <vs-col
                      vs-w="2.4"
                      class="my-0 mx-0"
                      :key="index"
                      v-for="(servico, index) in servicos"
                    >
                      <div class="mx-1 mb-1">
                        <vx-card class="m-0 p-0 card-border" no-shadow>
                          <vs-row
                            vs-w="12"
                            class="m-0 p-0"
                            vs-justify="center"
                            vs-align="center"
                            vs-type="flex"
                          >
                            <vs-col
                              vs-w="12"
                              class="m-0 p-0"
                              vs-justify="center"
                              vs-align="center"
                              vs-type="flex"
                            >
                              <label class="m-0 p-0">
                                <strong style="font-size: 9px; color: black"
                                  >{{ servico.tipo }}: {{ servico.id }}</strong
                                >
                              </label>
                            </vs-col>
                          </vs-row>
                          <vs-row>
                            <vs-col
                              vs-w="12"
                              vs-justify="center"
                              vs-align="center"
                              vs-type="flex"
                              class="m-0 p-0"
                            >
                              <label
                                class="m-0"
                                style="color: black; font-size: 9px"
                              >
                                <strong>
                                  {{ servico.data_servico_format }}
                                  {{
                                    servico.hora_marcada != null
                                      ? servico.hora_marcada
                                      : servico.turno
                                  }}
                                </strong>
                              </label>
                            </vs-col>
                          </vs-row>
                        </vx-card>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vx-card no-shadow class="border mx-0 my-1 p-0">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2 font-bold"
                  style="font-size: 10px; color: black; text-align: justify"
                >
                  {{ dataCertificado.razao_social }} |
                  {{ dataCertificado.cnpj_empresa }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2 font-bold"
                  style="font-size: 10px; color: black; text-align: justify"
                >
                  INSC.EST: {{ dataCertificado.inscricao_estadual }} |
                  INSC.MUNICIPAL: {{ dataCertificado.inscricao_municipal }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2 font-bold"
                  style="font-size: 10px; color: black; text-align: justify"
                >
                  {{ dataCertificado.endereco_empresa }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span
                  class="mx-2 font-bold"
                  style="font-size: 10px; color: black; text-align: justify"
                >
                  {{ dataCertificado.telefone_empresa }} |
                  {{ dataCertificado.website_empresa }}
                </span>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
export default {
  props: {
    os: null,
    dataCertificado: null,
    servicos: null,
  },
  data () {
    return {};
  },
  methods: {
    async updateValidade () {
      if (this.dataCertificado.data_vencimento == null) {
        this.$vs.notify({
          title: "Erro!",
          text: "A data de vencimento não pode ser nula!",
          color: "warning",
        });
        return;
      }
      this.$emit("update", this.dataCertificado);
    },
  },
};
</script>
