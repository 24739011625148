<template>
  <div class="w-full" id="general">
    <div class="w-full">
      <div class="w-full">
        <vx-card no-border no-shadow class="px-5 py-2 m-0 w-full">
          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="px-8"
          >
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 py-3">
                <vx-card
                  no-border
                  card-background="primary"
                  no-shadow
                  class="m-0 p-0"
                >
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="9"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <span
                          v-if="
                            dataCertificado.controle
                              .toUpperCase()
                              .trim()
                              .search('SANITIZ') !== -1
                          "
                          class="w-full font-bold"
                          style="
                            font-size: 24px;
                            color: black;
                            line-height: 1;
                            text-align: justify;
                          "
                        >
                          ESTA EMPRESA REALIZOU DESINFECÇÃO PARA VÍRUS,
                          BACTÉRIAS, ÁCAROS E FUNGOS.
                        </span>
                        <span
                          v-else
                          class="w-full font-bold"
                          style="
                            font-size: 24px;
                            color: black;
                            line-height: 1;
                            text-align: justify;
                          "
                          >ESTA EMPRESA REALIZOU O SERVIÇO DE CONTROLE DE
                          PRAGAS.</span
                        >
                      </div>
                    </vs-col>
                    <vs-col
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <img
                        width="100"
                        class="mx-5"
                        height="55"
                        src="./images/rato.png"
                      />
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full">
                <vx-card no-shadow no-border class="m-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        style="color: black; font-size: 40px"
                        class="font-bold mt-1"
                        >CERTIFICADO</span
                      >
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 pt-3">
                <vx-card no-border no-shadow class="m-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        v-if="
                          dataCertificado.controle
                            .toUpperCase()
                            .trim()
                            .search('SANITIZ') !== -1
                        "
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        Certificamos que a empresa possui um
                        <span
                          class="font-bold"
                          style="text-transform: uppercase"
                          >PLANO DE SANITIZAÇÃO</span
                        >
                        e que realizou a desinfecção em todo o ambiente com o
                        produto
                        <span
                          class="font-bold"
                          style="text-transform: uppercase"
                          >QUATERNÁRIO DE AMÔNIA</span
                        >
                        aplicado com equipamento de nebulização. Metodologia que
                        assegura até 98% de eliminação pontual para vírus,
                        bactérias, ácaros e fungos.
                      </span>
                      <span
                        v-else
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        Certificamos que o estabelecimento abaixo identificado
                        possui um
                        <span
                          class="font-bold"
                          style="text-transform: uppercase"
                        >
                          PLANO PREVENTIVO DE
                          {{ dataCertificado.controle }}
                        </span>
                        e que o mesmo atende todas as especificações técnicas
                        estabelecidas na
                        <b>RESOLUÇÃO - RDC Nº 622, DE 09 DE MARÇO DE 2022 - ANVISA</b
                        >. A garantia especificada neste certificado está
                        condicionada às inspeções de avaliações e/ou
                        <b>MANUTENÇÕES PREVENTIVAS</b> comprovadas através das ordens
                        de serviço respectivas.
                      </span>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 py-1">
                <vx-card no-shadow class="border mx-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>CONTRATO:</b>
                        {{ dataCertificado.id_contrato }}
                      </span>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>VALIDADE:</b>
                        {{ dataCertificado.vencimento }}
                      </span>
                    </vs-col>
                    <vs-col
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>EMISSÃO:</b>
                        {{ dataCertificado.data_emissao }}
                      </span>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 py-1">
                <vx-card no-shadow class="border mx-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>EMPRESA:</b>
                        {{ dataCertificado.nome_cliente }}
                      </span>
                    </vs-col>
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>CNPJ:</b>
                        {{ dataCertificado.cpf_cnpj }}
                      </span>
                    </vs-col>
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <span
                        class="mx-2"
                        style="
                          font-size: 10px;
                          color: black;
                          text-align: justify;
                        "
                      >
                        <b>ENDEREÇO:</b>
                        {{ dataCertificado.endereco }}
                      </span>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 py-1">
                <vx-card no-shadow class="border mx-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <div class="w-full m-0 p-0">
                        <vs-col vs-w="12" class="my-0 mx-2">
                          <label style="font-size: 10px; color: black">
                            Endereço:
                            <b>{{ dataCertificado.nomeEndereco }}</b>
                          </label>
                        </vs-col>
                        <vs-row
                          vs-justify="flex-start"
                          vs-align="center"
                          vs-type="flex"
                        >
                          <vs-col
                            vs-w="2"
                            :key="index"
                            v-for="(servico, index) in servicos"
                          >
                            <div class="mx-2">
                              <vs-row
                                vs-w="12"
                                class="m-0 p-0"
                                vs-justify="center"
                                vs-align="center"
                                vs-type="flex"
                              >
                                <vs-col
                                  vs-w="12"
                                  class="m-0 p-0"
                                  vs-justify="center"
                                  vs-align="center"
                                  vs-type="flex"
                                >
                                  <label class="m-0 p-0">
                                    <strong
                                      style="font-size: 6px; color: black"
                                    >
                                      {{ servico.tipo }}:
                                      {{ servico.id }}
                                    </strong>
                                  </label>
                                </vs-col>
                              </vs-row>
                              <vs-row>
                                <vs-col
                                  vs-w="12"
                                  vs-justify="center"
                                  vs-align="center"
                                  vs-type="flex"
                                  class="m-0 p-0"
                                >
                                  <label
                                    class="m-0"
                                    style="color: black; font-size: 7px"
                                  >
                                    <strong>
                                      {{
                                        servico.data_servico_format
                                      }}
                                      {{
                                        servico.hora_marcada != null
                                          ? servico.hora_marcada
                                          : servico.turno
                                      }}
                                    </strong>
                                  </label>
                                </vs-col>
                              </vs-row>
                            </div>
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
            >
              <div class="w-full px-5 pt-1">
                <vx-card no-shadow class="border mx-0 p-0">
                  <div class="w-full">
                    <vs-row
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-col
                        vs-w="10"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <div class="w-full m-2">
                          <vs-row
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="flex-start"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                            >
                              <span
                                class="mx-2 font-bold"
                                style="
                                  font-size: 10px;
                                  color: black;
                                  text-align: justify;
                                "
                              >
                                {{ dataCertificado.razao_social }} |
                                {{ dataCertificado.cnpj_empresa }}
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                            >
                              <span
                                class="mx-2 font-bold"
                                style="
                                  font-size: 10px;
                                  color: black;
                                  text-align: justify;
                                "
                              >
                                INSC.EST:
                                {{ dataCertificado.inscricao_estadual }} |
                                INSC.MUNICIPAL:
                                {{ dataCertificado.inscricao_municipal }}
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                            >
                              <span
                                class="mx-2 font-bold"
                                style="
                                  font-size: 10px;
                                  color: black;
                                  text-align: justify;
                                "
                                >{{ dataCertificado.endereco_empresa }}</span
                              >
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="center"
                            >
                              <span
                                class="mx-2 font-bold"
                                style="
                                  font-size: 10px;
                                  color: black;
                                  text-align: justify;
                                "
                              >
                                {{ dataCertificado.telefone_empresa }} |
                                {{ dataCertificado.website_empresa }}
                              </span>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col
                        vs-w="2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <img
                          width="70"
                          class="mx-2"
                          height="55"
                          src="./images/emops.png"
                        />
                      </vs-col>
                    </vs-row>
                  </div>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 pt-10">
                <vx-card no-shadow class="mx-0 mt-0 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <img
                        width="100"
                        class="mx-2"
                        height="55"
                        :src="selos.assinatura_engenheiro_quimico"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-divider
                        border-style="solid"
                        color="dark"
                        class="mr-2 py-0 my-0"
                      ></vs-divider>
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-divider
                        border-style="solid"
                        color="dark"
                        class="mr-2 py-0 my-0"
                      ></vs-divider>
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span style="font-size: 10px; color: black"
                        >e-Control (Responsável Técnico)</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span style="font-size: 10px; color: black"
                        >Empresa / Contratante</span
                      >
                    </vs-col>
                  </vs-row>
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span style="font-size: 10px; color: black">{{
                        dataCertificado.assinatura_certificado
                      }}</span>
                    </vs-col>
                  </vs-row>

                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="font-bold"
                        style="font-size: 10px; color: black"
                        >{{ dataCertificado.numero_registro }}</span
                      >
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>

            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5">
                <vx-card
                  no-border
                  card-background="primary"
                  no-shadow
                  class="m-0 p-0"
                >
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <div class="w-full">
                        <span
                          class="w-full font-bold"
                          style="font-size: 18px; color: black"
                          >CERTIFICAÇÕES</span
                        >
                      </div>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>

            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vx-card no-border no-shadow class="m-0 p-0">
                <div class="w-full px-5 pb-5">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <img
                        width="100"
                        class="mx-4"
                        height="55"
                        :src="selos.selo1"
                      />
                      <img
                        width="100"
                        class="mx-4"
                        height="55"
                        :src="selos.selo2"
                      />
                      <img
                        width="100"
                        class="mx-4"
                        height="55"
                        :src="selos.selo3"
                      />
                      <img
                        width="100"
                        class="mx-4"
                        height="55"
                        :src="selos.selo4"
                      />
                    </vs-col>
                  </vs-row>
                </div>
              </vx-card>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selos: null,
    os: null,
    dataCertificado: null,
    servicos: null
  }
};
</script>
