<template>
  <div class="w-full">
    <div>
      <vs-row vs-w="12" vs-align="flex-start" vs-justify="center">
        <vs-col vs-w="6" vs-align="center" vs-justify="center">
          <div id="simple-calendar-app" class="mr-3">
            <div class="vx-card no-scroll-content">
              <calendar-view
                ref="calendar"
                :displayPeriodUom="calendarView"
                :show-date="showDate"
                :events="[]"
                :class="blockActions ? 'block-cursor' : 'cursor-pointer'"
                locale="pt-br"
                monthNameFormat="numeric"
                eventBorderHeight="0px"
                eventContentHeight="1.65rem"
                locale-pt
                class="theme-default"
                :starting-day-of-week="1"
                @click-date="select"
              >
                <div slot="header" class="mb-4">
                  <div class="vx-row no-gutter">
                    <!-- Current Month -->
                    <div
                      class="vx-col sm:w-3/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
                    >
                      <div class="flex items-center">
                        <feather-icon
                          :icon="
                            $vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'
                          "
                          @click="updateMonth(-1)"
                          svgClasses="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full"
                        />

                        <span
                          class="mx-3 text-xl font-medium whitespace-no-wrap"
                        >
                          {{
                            meses[showDate.getMonth()] +
                            " - " +
                            showDate.getFullYear()
                          }}</span
                        >

                        <feather-icon
                          :icon="
                            $vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'
                          "
                          @click="updateMonth(1)"
                          svgClasses="w-5 h-5 m-1"
                          class="cursor-pointer bg-primary text-white rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </calendar-view>
            </div>
          </div>
        </vs-col>
        <vs-col vs-w="6" vs-align="center" vs-justify="center">
          <component
            v-bind:is="showCardForca"
            :key="reRenderCard"
            :data="dataForca"
          >
          </component>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import moduleCalendar from "@/store/calendar/moduleCalendar.js"
import cardForca from "./cardForca.vue"

require("vue-simple-calendar/static/css/default.css")

import Datepicker from "vuejs-datepicker"

export default {
  props: {
    blockActions: { default: false },
  },
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
    cardForca,
  },
  data () {
    return {
      showCardForca: null,
      reRenderCard: 0,
      showDate: new Date(),
      calendarView: "month",
      dataForca: this.$formartData.removeTimeZone(new Date()).split("T")[0],
      meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  },
  methods: {
    async select (d) {
      if (this.blockActions) {
        return
      }
      this.dataForca = await this.$formartData
        .removeTimeZone(d)
        .split("T")[0]
      this.$emit("data-selecionada", this.dataForca)
      this.reRenderCard += 1
      return
    },

    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
  },
  async created () {
    this.$store.registerModule("calendar", moduleCalendar)
  },
  beforeDestroy () {
    this.$store.unregisterModule("calendar")
  },
  mounted () {
    this.showCardForca = "cardForca"
    this.reRenderCard += 1
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
.cv-week,
.cv-weeks {
  height: 24rem;
  //width: 800px !important;
}
.block-cursor {
  &:hover {
    color: lightgrey;
    cursor: not-allowed;
  }
}
.theme-default .cv-event {
  font-size: 0.9rem;
  border-radius: 0%;
  background-color: rgba(202, 202, 202, 0.233);
  color: black !important;
}
</style>
