<template>
  <div class="w-full">
    <vs-row vs-w="12" vs-align="flex-start" vs-justify="center">
      <vs-col vs-w="12" vs-align="center" vs-justify="center">
        <Agenda
          :blockActions="blockActions"
          @data-selecionada="setDataSelecionada"
        ></Agenda>
      </vs-col>
    </vs-row>
    <vs-row vs-w="12" vs-align="flex-start" vs-justify="center">
      <vs-col vs-w="12" vs-align="center" vs-justify="center" class="mt-5">
        <div class="w-full">
          <div id="agenda-os" class="customize-table vs-con-loading__container">
            <div class="w-full">
              <div class="w-full" style="background: black">
                <h5 class="text-white p-2">
                  Total de Serviços: {{ servicos.length }}
                </h5>
              </div>
              <!-- <VuePerfectScrollbar class="scroll-agenda-os" :settings="settings"> -->
              <vs-table
                stripe
                search
                maxHeight="63vh"
                :key="reRenderTable"
                pagination
                :data="servicos"
                noDataText="Nenhum Serviço Agendado!"
                max-items="10"
              >
                <template slot="header">
                  <vs-row
                    class="my-3"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                      vs-w="9"
                    >
                      <el-select
                        filterable
                        @change="getServicosByData"
                        clearable
                        placeholder="Cidade"
                        class="mx-5"
                        v-model="filtro.cidade"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="cidade in cidades"
                          :value="cidade.id"
                          :label="cidade.nome"
                          :key="cidade.id"
                        ></el-option>
                      </el-select>
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                      vs-w="3"
                    >
                      <el-select
                        filterable
                        @change="filterStatusServico"
                        clearable
                        placeholder="Status do Serviço"
                        class="mx-5"
                        v-model="statusSelecionado"
                        :popper-append-to-body="false"
                      >
                        <el-option
                          v-for="status in statusServico"
                          :value="status.id"
                          :label="status.nome"
                          :key="status.id"
                        ></el-option>
                      </el-select>
                    </vs-col>
                  </vs-row>
                </template>
                <template slot="thead">
                  <vs-th sort-key="id_contrato" style="width: 5%" id="center"
                    >Contrato</vs-th
                  >
                  <vs-th sort-key="id" style="width: 8%" id="center"
                    >N° Os</vs-th
                  >
                  <vs-th sort-key="id_cliente" style="width: 18%"
                    >Cliente</vs-th
                  >
                  <vs-th style="width: 8%" id="center">Tipo Servico</vs-th>
                  <vs-th style="width: 10%" id="center">Força</vs-th>
                  <vs-th style="width: 12%" id="center">Controle</vs-th>
                  <vs-th style="width: 5%" id="center">Horário Marcado</vs-th>
                  <vs-th style="width: 15%" id="center">Cidade \ Bairro</vs-th>
                  <vs-th style="width: 10%" id="center"
                    >Relatório Indicativo</vs-th
                  >
                  <vs-th style="width: 10%" id="center">Ações</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="index"
                    v-for="(row, index) in data"
                    :style="
                      row.executado == 1
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : row.executado == 2
                        ? 'color:#dd3522;background-color: #f5efef !important'
                        : ''
                    "
                  >
                    <vs-td :data="row.id_contrato" class="text-center">
                      <a
                        target="_blank"
                        :href="'contrato?id=' + row.id_contrato"
                        style="color: darkblue; cursor: pointer"
                      >
                        <b>{{ row.id_contrato }}</b>
                      </a>
                    </vs-td>
                    <vs-td :data="row.id" class="text-center">
                      <b>{{ row.id }}</b>
                    </vs-td>

                    <vs-td :data="row.id_cliente">
                      <VisualizarCliente
                        @update="close()"
                        :id_cliente="row.id_cliente"
                        :nomeCliente="row.nome_cliente"
                      />
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.tipo_servico }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <b
                        >{{ row.horas_exec }} horas /
                        {{ row.tecnicos_exec }} téc.</b
                      >
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.controle }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.horario }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.cidade }} \ {{ row.bairro }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <b>{{ row.relatorioExists == 1 ? "Sim" : "Não" }}</b>
                    </vs-td>
                    <vs-td class="text-center">
                      <div class="w-full">
                        <vs-row
                          vs-w="12"
                          vs-align="flex-start"
                          vs-justify="center"
                        >
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                          >
                            <vs-button
                              class="px-0 py-0 m-0"
                              icon="insert_drive_file"
                              color="warning"
                              size="small"
                              type="filled"
                              @click="openCertificadoOS(row)"
                            ></vs-button>
                          </vs-col>
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                            v-if="row.executado != 2"
                          >
                            <vs-button
                              class="px-0 py-0 m-0"
                              @click="redirectBlank(row)"
                              icon="remove_red_eye"
                              :disabled="!(row.executado == 1)"
                              color="success"
                              size="small"
                              type="filled"
                            ></vs-button>
                            <!-- </a> -->
                          </vs-col>
                          <vs-col
                            vs-w="6"
                            vs-align="center"
                            vs-justify="center"
                            v-else
                          >
                            <OsNaoExec :os="row" />
                          </vs-col>
                        </vs-row>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <!-- </VuePerfectScrollbar> -->
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <component
      v-bind:is="modalCertificadoOS"
      :key="reRenderModalCertificadoOS"
      :os="osSelected"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import Agenda from "./components/agenda.vue";
import OsNaoExec from "./components/osNaoExec";
import VisualizarCliente from "./../../cliente/visualizar.vue";
import CertificadoOS from "./../../certificado/certificadoOS.vue";
export default {
  data() {
    return {
      filtro: {
        data: this.$formartData.dataFiltro(),
        dataHoje: this.$formartData.dataFiltro(),
        arroz: 1,
      },
      servicos: [],
      cidades: [],
      cidade: [],
      originServicos: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      reRenderTable: 0,
      statusSelecionado: null,
      blockActions: false,
      statusServico: [
        { id: null, nome: "Todos" },
        { id: 3, nome: "Pendente" },
        { id: 1, nome: "Executado" },
        { id: 2, nome: "Não Executado" },
      ],
      modalCertificadoOS: "",
      reRenderModalCertificadoOS: 0,
      osSelected: {},
      relatorioUrl: "",
    };
  },
  methods: {
    async setDataSelecionada(data) {
      this.filtro.data = await this.$formartData.formatar(data, 1);
      await this.getServicosByData();
    },
    async getServicosByData() {
      await this.$vs.loading({ container: "#agenda-os", scale: 0.6 });
      this.blockActions = true;
      try {
        const res = await this.$http.post("getAgendaOS", this.filtro);
        this.relatorioUrl = res.relatorioUrl;
        this.originServicos = res.servicos;
        this.servicos = Object.assign(this.originServicos);
        await this.filterStatusServico(this.statusSelecionado);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockActions = false;
        await this.$vs.loading.close("#agenda-os > .con-vs-loading");
      }
    },
    async getCidades() {
      await this.$vs.loading({ container: "#agenda-os", scale: 0.6 });
      try {
        this.cidades = await this.$http.get("getCidadesFiltro");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockActions = false;
        await this.$vs.loading.close("#agenda-os > .con-vs-loading");
      }
    },
    async redirectBlank(os) {
      const link = `${this.relatorioUrl}/?os=${os.id}`;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    },
    async openCertificadoOS(os) {
      this.osSelected = os;
      this.modalCertificadoOS = "CertificadoOS";
      this.reRenderModalCertificadoOS++;
    },
    async filterStatusServico(statusSelect) {
      if (statusSelect == 1 || statusSelect == 2) {
        this.servicos = this.originServicos.filter(
          (element) => element.executado == statusSelect
        );
      } else if (statusSelect == 3) {
        this.servicos = this.originServicos.filter(
          (element) => element.executado == 0 || element.executado == null
        );
      } else {
        this.servicos = Object.assign(this.originServicos);
      }
      this.reRenderTable += 1;
    },
  },
  async mounted() {
    await this.getCidades();
    await this.getServicosByData();
  },
  components: {
    ...components,
    Agenda,
    VisualizarCliente,
    OsNaoExec,
    CertificadoOS,
  },
};
</script>
<style lang="scss">
.scroll-agenda-os {
  height: 73vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.customize-table {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
