<template >
  <div class="w-full">
    <vs-popup
      :background-color-popup="colorx"
      title="RESUMO DO CERTIFICADO"
      :active.sync="resumoModal"
    >
      <div class="w-full">
        <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <VuePerfectScrollbar
              class="scroll-certificado-os"
              :settings="settings"
            >
              <div
                class="w-full vs-con-loading__container"
                id="loadingCertificadoOS"
              >
                <resumo-certificado
                  :dataCertificado="dataCertificado"
                  :servicos="servicos"
                  @update="updateDataCertificado"
                />
              </div>
            </VuePerfectScrollbar>
          </vs-col>

          <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              @click="imprimirPDFPreImpresso()"
              class="w-full mt-5 mr-1"
              color="warning"
              type="relief"
              >PRÉ-IMPRESSO</vs-button
            >
          </vs-col>
          <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              @click="imprimirPDFA4()"
              class="w-full mt-5 ml-1"
              color="success"
              type="relief"
              >PDF A4</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
    <vue-html2pdf
      :show-layout="false"
      :preview-modal="false"
      :paginate-elements-by-height="12000"
      :filename="filename"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @hasStartedDownload="hasStartedDownload()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <component
          v-bind:is="pdf"
          :key="reRenderPdf"
          :selos="selos"
          :dataCertificado="dataCertificado"
          :servicos="servicos"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import resumoCertificado from "./components/resumoCertificado"
import printDoc from "./components/printDoc"
import printDocA4 from "./components/printDocA4"
import VueHtml2pdf from "vue-html2pdf"
export default {
  props: {
    os: null
  },
  data () {
    return {
      dataCertificado: {},
      colorx: "#030e1d",
      resumoModal: false,
      servicos: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      pdf: "",
      reRenderPdf: 0,
      filename: "",
      selos: {}
    }
  },
  methods: {
    async getSelosCertificado () {
      try {
        const res = await this.$http.get("getSelosCertificado")
        this.selos = {
          assinatura_engenheiro_quimico: `${res.storage_cdn_url}/${res.assinatura_engenheiro_quimico}`,
          selo1: `${res.storage_cdn_url}/${res.selo_certificado1}`,
          selo2: `${res.storage_cdn_url}/${res.selo_certificado2}`,
          selo3: `${res.storage_cdn_url}/${res.selo_certificado3}`,
          selo4: `${res.storage_cdn_url}/${res.selo_certificado4}`
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async updateDataCertificado (dataCertificadoEdit) {
      this.dataCertificado.data_vencimento =
        dataCertificadoEdit.data_vencimento
      this.dataCertificado.vencimento = await this.$formartData.formatar(
        new Date(dataCertificadoEdit.data_vencimento)
      )
    },
    async getDataCertificado () {
      try {
        this.dataCertificado = await this.$http.post("getDataCertificado", {
          id_os: this.os.id
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async hasStartedDownload () {
      await this.$vs.loading({
        container: "#loadingCertificadoOS",
        scale: 0.6
      })
    },
    async hasGenerated (event) {
      event
      await this.$vs.loading.close("#loadingCertificadoOS > .con-vs-loading")
    },
    async getServicosEndereco () {
      try {
        const controle = this.dataCertificado.controle.toUpperCase().trim()
        if (controle.search("SANITIZ") !== -1) {
          const res = await this.$http.post("getServicosEndereco", {
            id_endereco: this.os.id_endereco,
            id_contrato: this.os.id_contrato
          })
          this.servicos = res.servicos
        } else {
          this.servicos = await this.$http.post("getServicos2Cerficado", {
            id_endereco: this.os.id_endereco,
            id_contrato: this.os.id_contrato
          })
        }
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async imprimirPDFA4 () {
      this.pdf = "printDocA4"
      this.reRenderPdf += 1
      await this.$refs.html2Pdf.generatePdf()
    },
    async imprimirPDFPreImpresso () {
      this.pdf = "printDoc"
      this.reRenderPdf += 1
      await this.$refs.html2Pdf.generatePdf()
    },
    async configPDF () {
      this.filename = `Certificado-OS-${this.os.id}`
      this.pdf = "printDocA4"
      this.reRenderPdf += 1
    }
  },
  async mounted () {
    this.$vs.loading({ container: "#loadingCertificadoOS", scale: 0.9 })
    this.resumoModal = true
    await this.getDataCertificado()
    await this.getServicosEndereco()
    await this.getSelosCertificado()
    await this.configPDF()

    this.$vs.loading.close("#loadingCertificadoOS > .con-vs-loading")
  },
  components: {
    resumoCertificado,
    ...components,
    VueHtml2pdf,
    printDoc,
    printDocA4
  }
};
</script>
<style scoped>
.scroll-certificado-os {
  max-height: 65vh !important;
}
</style>
