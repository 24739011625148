<template>
  <div class="w-full roboto" id="general">
    <div class="w-full">
      <div class="w-full">
        <vx-card no-border no-shadow class="px-5 py-2 mt-20 mx-0 w-full">
          <vs-row
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            class="px-8 pt-20 mt-20"
          >
            <vs-col
              vs-w="12"
              class="mt-20 py-20"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 pt-20">
                <vx-card no-shadow no-border class="pt-20 mt-8">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="1.5"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4>&nbsp;</h4>
                    </vs-col>
                    <vs-col
                      vs-w="2.5"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="mr-1"
                        style="
                          font-size: 11px;
                          color: black;
                          font-weight: bold;
                          text-align: justify;
                        "
                        >{{ dataCertificado.id_contrato }}</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="1.5"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4>&nbsp;</h4>
                    </vs-col>
                    <vs-col
                      vs-w="2.5"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="mr-1"
                        style="
                          font-size: 11px;
                          font-weight: bold;
                          color: black;
                          text-align: justify;
                        "
                        >{{ dataCertificado.vencimento }}</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <h4>&nbsp;</h4>
                    </vs-col>
                    <vs-col
                      vs-w="2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class
                        style="
                          font-size: 11px;
                          margin-left: 30px;
                          font-weight: bold;
                          color: black;
                          text-align: justify;
                        "
                        >{{ dataCertificado.data_emissao }}</span
                      >
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <div class="w-full px-5 pt-10 mt-2">
                <vx-card no-shadow no-border class="mx-0 my-1 p-0">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="1"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      >&nbsp;</vs-col
                    >
                    <vs-col
                      vs-w="5.5"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <span
                        class="mr-2 truncate"
                        style="
                          font-size: 11px;
                          color: black;
                          font-weight: bold;
                          text-align: justify;
                        "
                        >{{ dataCertificado.nome_cliente }}</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="2.5"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      >&nbsp;</vs-col
                    >
                    <vs-col
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="truncate"
                        style="
                          font-size: 10px;
                          font-weight: bold;
                          color: black;
                          text-align: justify;
                        "
                        >{{ dataCertificado.cpf_cnpj }}</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="1.2"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      >&nbsp;</vs-col
                    >
                    <vs-col
                      class="mt-3"
                      vs-w="5.3"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <span
                        class="mr-2 truncate"
                        style="
                          font-size: 9px;
                          color: black;
                          font-weight: bold;
                          text-align: justify;
                        "
                        >{{ dataCertificado.endereco }}</span
                      >
                    </vs-col>
                    <vs-col
                      vs-w="2.5"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      >&nbsp;</vs-col
                    >
                    <vs-col
                      class="mt-3"
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <span
                        class="truncate"
                        style="
                          font-size: 11px;
                          color: black;
                          font-weight: bold;
                          text-align: justify;
                        "
                        >{{ dataCertificado.nomeBairro }}</span
                      >
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <div class="w-full mx-5 mt-5">
                <vs-row
                  vs-justify="flex-end"
                  vs-align="center"
                  vs-type="flex"
                  vs-w="12"
                >
                  <vs-col
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-type="flex"
                    vs-w="7"
                  >
                    <div class="w-full">
                      <vs-row
                        class="m-0 p-0"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-type="flex"
                        vs-w="12"
                      >
                        <vs-col
                          vs-w="2"
                          class="my-0 mx-0 p-0"
                          vs-justify="flex-end"
                          vs-align="flex-end"
                          vs-type="flex"
                          style="line-height: 1.5"
                          :key="index"
                          v-for="(servico, index) in servicos"
                        >
                          <div class="w-full mx-2 my-0 py-0">
                            <vx-card class="m-0 p-0 ml-5" no-border no-shadow>
                              <vs-row class="p-0 m-0">
                                <vs-col
                                  vs-w="12"
                                  class="p-0 m-0"
                                  vs-justify="flex-start"
                                  vs-align="center"
                                  vs-type="flex"
                                >
                                  <label
                                    class="m-0 p-0"
                                    style="
                                      color: black;
                                      font-size: 9.5px;
                                      font-weight: bold;
                                    "
                                    >{{
                                      convertedMonths[servico.mesServico]
                                    }}/{{ servico.anoServico }}</label
                                  >
                                </vs-col>
                              </vs-row>
                              <vs-row
                                vs-w="12"
                                class="m-0 p-0"
                                vs-justify="center"
                                vs-align="center"
                                vs-type="flex"
                              >
                                <vs-col
                                  vs-w="12"
                                  class="m-0 p-0"
                                  vs-justify="flex-start"
                                  vs-align="center"
                                  vs-type="flex"
                                >
                                  <label
                                    class="m-0 p-0"
                                    style="
                                      color: black;
                                      font-size: 9.5px;
                                      font-weight: bold;
                                    "
                                    >{{ servico.diaServico }}</label
                                  >
                                </vs-col>
                              </vs-row>
                              <vs-row
                                vs-w="12"
                                class="m-0 p-0"
                                vs-justify="flex-start"
                                vs-align="center"
                                vs-type="flex"
                              >
                                <vs-col
                                  vs-w="12"
                                  class="m-0 p-0"
                                  vs-justify="flex-start"
                                  vs-align="center"
                                  vs-type="flex"
                                >
                                  <label
                                    class="m-0 p-0"
                                    style="
                                      color: black;
                                      font-size: 9.5px;
                                      font-weight: bold;
                                    "
                                  >
                                    {{
                                      servico.hora_marcada != null
                                        ? servico.hora_marcada
                                        : servico.turno
                                    }}
                                  </label>
                                </vs-col>
                              </vs-row>
                            </vx-card>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    os: null,
    dataCertificado: null,
    servicos: null,
  },
  data() {
    return {
      convertedMonths: {
        Jan: "JAN",
        Feb: "FEV",
        Mar: "MAR",
        Apr: "ABR",
        May: "MAI",
        Jun: "JUN",
        Jul: "JUL",
        Aug: "AGO",
        Sep: "SET",
        Oct: "OUT",
        Nov: "NOV",
        Dec: "DEZ",
      },
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}
</style>